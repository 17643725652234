<template>
    <div class="contentMenu">
        <span class="guide-ttl">{{response.trouble_shoot_title}}</span>
        <span class="preview" @click="preview = true">guide preview</span>
        <ul class="navbar-nav">
            <li class="nav-item active">
            </li>
        </ul>
    </div>

    <div class="steps">
        <div class="step-content" :class="{dragging: draggingItem === entry.id}" v-for="(entry, index) in newEntries" :key="index" draggable="true" @dragstart="handleDragStart(index)" @dragenter="handleDragEnter(index)" @dragover.prevent="handleDragOver" @dragend="handleDragEnd" >
            <span class="re-order" />
            <div class="step-name" @click="goToStep(entry)">
                <div class="step-number">
                <span>{{ index + 1 }} -</span>
                </div>
                <span>{{ entry.title }}</span>
            </div>
            <div class="step-actions">
                <img src="/assets/images/down_arrow.svg" @click="goToStep(entry)"/>
                <span class="dots">
                <img src="/assets/images/tripledots.svg" @click="entry.edit = !entry.edit"/>
                <options-modal v-if="entry.edit" :options="['edit']" @edit="goToStep(entry)" @delete="deleteStep(entry.id)" />
                </span>
            </div>
        </div>
    </div>

    <div class="add-step-ct">
        <div class="add-step" @click="addStep">
            Add New Step
        </div>
    </div>

      
    <preview
        v-if="preview"
        :type="'step-desktop'"
        :info="{ id: guideId }"
        @close="preview = false"
    />
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';
import { mapActions, mapMutations } from 'vuex';
import optionsModal from '@/components/Ui/General/Options.vue';
import Preview from "@/components/previews/Preview.vue";

import {
  DELETE_PROJECT_ACTION
} from '@/store/storeconstants';

export default {
  title () {
    return `Remote Assistance | ${this.title}`
  },
  data: function() {
    return {
      title: 'Guides',
      entries: [],
      response:'',
      currentPage: 1,
      trbl_id:'',
      total: 100,
      perPage: 5,
      vw: true,
      edt: true,
      del: true,
      isCreateTroubleModalVisible: false,
      isViewStepDetailsModalVisible: false,
      preview: false,
      draggingItem: undefined,
    };
  },
  components:{
      optionsModal,
      Preview
  },
  computed:{
      ...mapActions('project', {
          deleteProject: DELETE_PROJECT_ACTION
      }),
      newEntries() {
        let entries = this.entries
        entries.forEach((entry) => {
          entry.edit = false
        });
        return entries
      },
  },
  methods:{
      ...mapMutations({
          pathing: 'pathing/setPathing'
      }),
      addStep() {
          this.$router.push(`/products/guides/${this.$route.params.catid}/addstep/${this.response.trouble_shoot_id}`);
      },

      goToStep(entry) {
        this.$router.push({path: `/products/guides/${this.$route.params.catid}/editstep/${entry.id}`})
      },
      async deleteStep(id){
        const response = await axiosInstance.delete(`delete-step/${id}`);
        if(response.status === 200) this.getData(this.currentPage)
    },

      async getData(value){
          this.guideId = this.$route.params.guideid;
          this.currentPage = value;
          let orgDataString = localStorage.getItem('organization');
          if(orgDataString){
              let response = await axiosInstance.get(`show-step/${this.guideId}?page=${this.currentPage}`);
              let responseData = response.data.data;
              this.response= responseData;
              this.entries = responseData.steps;
              this.trbl_id = responseData.trouble_cat_id;
              this.pathing([{name: 'categories', link: '/products/guides'}, {name: `Category`, link:`/products/guides/${this.$route.params.catid}`}, {name: this.response.trouble_shoot_title.toLowerCase(), link:`/products/step-list/${this.response.trouble_shoot_id}`}])
              this.perPage = responseData.per_page;
              this.total = responseData.no_of_records;
          }
      },

      viewStep(trouble_id){
        this.emitter.emit("view-step", trouble_id);
      },

      handleDragStart(index) {
        this.draggingItem = this.newEntries[index].id
      },

      handleDragOver() {
        return false
      },

      handleDragEnter(i) {
        if (this.newEntries[i].id === this.draggingItem) return
        
        const item =  this.newEntries.find(el => el.id === this.draggingItem)
        const currentIndex = this.newEntries.findIndex(el => el.id === this.draggingItem)
        
        this.newEntries.splice(currentIndex, 1)
        this.newEntries.splice(i, 0, item);
      },

      async handleDragEnd() {
        this.draggingItem = undefined

        const toSend = {
            guide_id: this.$route.params.guideid,
            steps: this.newEntries.map((el, i) => {
                return {
                    id: el.id,
                    sequence: i + 1
                }
            })
        }


        await axiosInstance.patch(`reorder-steps`, toSend)
      }
  },
  mounted(){
      this.currentPage = 1;
      this.getData(this.currentPage);
  },
};
</script>

<style lang="scss" scoped>
.contentMenu {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
  .guide-ttl {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: capitalize;
  }
  .preview {
    color: var(--primary-color);
    text-decoration: underline;
    text-transform: capitalize;
    user-select: none;
    font-size: 0.8rem;
    cursor: pointer;
  }
  .content-title {
    padding: 10px 0;
  }
  .steps {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  .steps .step-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--primary-color);
    color: white;
  }

    .step-content {
        cursor: pointer;

        &.dragging {
            opacity: 0.4;
        }
        
        .re-order {
            width: 15px;
            height: 4px;
            margin-left: 20px;
            position: relative;

            &::before, &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background: white;
            }

            &::before {
                top: 0;
            }
            &::after {
                bottom: 0;
            }
        }
    }

  .steps .step-content .step-name{
    cursor: pointer;
    user-select: none;
    display: flex;
    gap: 5px;
    text-decoration: none;
    color: white;
    width: 100%;
    padding: 20px;
  }
  .steps .step-content .step-name .step-number{
    display: flex;
    gap: 5px;
  }

  .steps .step-content .step-actions{
    display: flex;
    gap: 15px;
    padding: 20px;
  }
  .steps .step-content .step-actions img{
    width: 15px;
    cursor: pointer;
  }

  .steps .step-content .step-actions .dots{
    position: relative;
    cursor: pointer;
    display: flex;
  }

  .add-step-ct{
    margin: 50px 0;
    width: 100%;
    display: flex;
    justify-content: center
  }
  .add-step-ct .add-step{
    display: flex;
    justify-content: center;
    border: solid 1px rgba(128, 128, 128, 0.2);
    color: var(--primary-color);
    padding: 10px 5%;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;
  }
  .add-step-ct .add-step:hover{
    background: var(--primary-color);
    color: white;
  }
</style>